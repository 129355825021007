<div class="container footer text-muted">
  <div class="row">
    <div class="col">AzzrkPassword System Web</div>
    <div class="col-8 text-center">
      ALL copyrights Saved To Azzrk&reg; Azzrk.com
    </div>
    <div class="col text-right">
      {{ "versionNumber" | i18n : version }}
    </div>
  </div>
</div>
