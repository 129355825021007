<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  <environment-selector></environment-selector>
  AzzrkPassword System <br />
  {{ "versionNumber" | i18n : version }}
  <br /><br />
  <div class="small">
    ALL copyrights Saved To Azzrk&reg; Azzrk.com
<br />\n  </div>
</div>
